import { CriteriaDimension, SortDirection } from '@openx/types';

import type { MappedSortCriteria } from 'types';

import { ColumnKey } from '../constants';

type CriteriaParams = {
  sort: { direction?: SortDirection; column?: string };
  pagination: {
    pageNumber: number;
    totalCount: number;
  };
  phrase: string;
};

export const mapSearchParamsToCriteria = (params: CriteriaParams): MappedSortCriteria => {
  const {
    sort: { column, direction },
    pagination: { pageNumber, totalCount },
  } = params;

  return {
    [CriteriaDimension.SORT]: {
      column: column || ColumnKey.NAME,
      direction: direction || SortDirection.ASC,
      sortableColumns: [ColumnKey.NAME, ColumnKey.DISTRIBUTION_CHANNEL, ColumnKey.AD_PLACEMENT],
    },
    [CriteriaDimension.PAGINATION]: {
      pageNumber: pageNumber || 1,
      pageSize: 50,
      totalCount: totalCount || 0,
    },
    phrase: params.phrase,
  };
};
