import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { TableCell, Tooltip, type Theme, IconButton, type SxProps } from '@mui/material';
import { styled, css } from '@mui/material/styles';
import { useCallback, useMemo } from 'react';

import { type BaseRow, type TableColumn, SortDirection } from '@openx/types';

const sharedArrowIconStyles = (theme: Theme) => css`
  font-size: 14px;
  margin-left: ${theme.spacing(0.5)};
`;

const StyledArrowUpward = styled(ArrowUpward)`
  ${({ theme }) => sharedArrowIconStyles(theme)}
`;

const StyledArrowDownward = styled(ArrowDownward, { shouldForwardProp: prop => prop !== 'isDisabled' })<{
  isDisabled?: boolean;
}>`
  ${({ theme }) => sharedArrowIconStyles(theme)}

  ${({ theme, isDisabled }) =>
    isDisabled &&
    css`
      color: ${theme.palette.action.inactive};
    `}
`;

const StyledHeaderCellContainerDiv = styled('div')`
  align-items: center;
  display: flex;
`;

const StyledTableCell = styled(TableCell, {
  shouldForwardProp: prop => prop !== 'isSortable' && prop !== 'isSortActive',
})<{ isSortable: boolean; isSortActive: boolean }>`
  ${({ isSortable, theme }) =>
    isSortable &&
    css`
      &:hover {
        color: ${theme.palette.primary.main};
        cursor: pointer;
      }
    `}

  ${({ isSortActive, theme }) =>
    isSortActive &&
    css`
      color: ${theme.palette.primary.main};
    `}
`;

export interface TableHeaderCellProps<RowT extends BaseRow> {
  column: TableColumn<RowT>;
  toggleSort?: (columnKey: string) => void;
  appliedSortDirection?: SortDirection;
  overrideTableCellStyles?: SxProps<Theme>;
}

export function TableHeaderCell<RowT extends BaseRow>(props: TableHeaderCellProps<RowT>): JSX.Element {
  const { column, toggleSort, appliedSortDirection, overrideTableCellStyles } = props;
  const sortable = !!toggleSort;
  const sortActive = !!appliedSortDirection;

  const directionIconElement = useMemo(() => {
    if (!toggleSort) {
      return;
    } else if (!sortActive) {
      return <StyledArrowDownward data-test="sort-down" isDisabled />;
    }

    return appliedSortDirection === SortDirection.ASC ? (
      <StyledArrowUpward data-test="sort-up" />
    ) : (
      <StyledArrowDownward fontSize="small" data-test="sort-down" />
    );
  }, [appliedSortDirection, sortActive, toggleSort]);

  const toggleColumnSort = useCallback(() => toggleSort?.(column.key), [toggleSort, column.key]);

  const columnContent = useMemo(() => {
    if (column.tooltipMessage) {
      return (
        <span>
          {column.title}
          {directionIconElement}
          <Tooltip key="tooltip" title={column.tooltipMessage} placement="top">
            <IconButton size="small" data-test="tooltip-icon">
              <InfoRoundedIcon />
            </IconButton>
          </Tooltip>
        </span>
      );
    }

    return (
      <>
        {column.title}
        {directionIconElement}
      </>
    );
  }, [directionIconElement, column.tooltipMessage, column.title]);

  return (
    <StyledTableCell
      style={{ width: column.calcWidth ?? column.width }}
      data-test="table-cell"
      onClick={toggleColumnSort}
      isSortActive={sortActive}
      isSortable={sortable}
      sx={overrideTableCellStyles}
    >
      <StyledHeaderCellContainerDiv>{columnContent}</StyledHeaderCellContainerDiv>
    </StyledTableCell>
  );
}
