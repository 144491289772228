import { Box } from '@mui/material';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ContentObjectOptions, Criteria, type ContentObjectProps } from '@openx/types';

import { EmptyFilterMessage, DimensionChipLine, Switch } from '../../shared';
import type { TargetingItemsProps } from '../../types';
import { filterEmptyParams, getExcludeAnyWarning } from '../utils';

import { DefaultItems, IntersectItems, IntersectOptionsItems, HierarchyItems } from './components';
import { EpisodeItems } from './episode';
import { ExcludeAnyAlert } from './ExcludeAnyAlert';

interface ContentObjectItemsProps extends TargetingItemsProps<ContentObjectProps> {
  dataTest?: string;
}

export const ContentObjectItems = ({
  targetingParams,
  readonly,
  onChange = () => {},
}: ContentObjectItemsProps): JSX.Element => {
  const { t } = useTranslation();

  const switchState = targetingParams[ContentObjectOptions.CONTENT_INTER_DIMENSION_OPERATOR] === Criteria.ANY;

  const dimensionChipLine = useMemo(
    () => <DimensionChipLine switchState={switchState} isSubsection={switchState} />,
    [switchState]
  );

  const shouldDisplayAlert = useMemo(
    () => !readonly && getExcludeAnyWarning(targetingParams),
    [readonly, targetingParams]
  );

  const items = useMemo(
    () =>
      Object.keys(filterEmptyParams(targetingParams))
        .map((type, index, currentArray) => {
          switch (type) {
            case ContentObjectOptions.LANGUAGE:
              return (
                <Fragment key={type}>
                  <IntersectOptionsItems
                    targetingParams={targetingParams}
                    type={type}
                    readonly={readonly}
                    onChange={onChange}
                  />
                  {index < currentArray.length - 1 && dimensionChipLine}
                </Fragment>
              );

            case ContentObjectOptions.LIVESTREAM:
              return (
                <Fragment key={type}>
                  <DefaultItems targetingParams={targetingParams} type={type} readonly={readonly} onChange={onChange} />
                  {index < currentArray.length - 1 && dimensionChipLine}
                </Fragment>
              );

            case ContentObjectOptions.CONTENT_RATING:
            case ContentObjectOptions.GENRE:
              return (
                <Fragment key={type}>
                  <HierarchyItems
                    targetingParams={targetingParams}
                    type={type}
                    readonly={readonly}
                    onChange={onChange}
                  />
                  {index < currentArray.length - 1 && dimensionChipLine}
                </Fragment>
              );

            case ContentObjectOptions.EPISODE:
              return (
                <Fragment key={type}>
                  <EpisodeItems targetingParams={targetingParams} readonly={readonly} onChange={onChange} />
                  {index < currentArray.length - 1 && dimensionChipLine}
                </Fragment>
              );

            case ContentObjectOptions.NETWORK:
            case ContentObjectOptions.CHANNEL:
            case ContentObjectOptions.SERIES:
              return (
                <Fragment key={type}>
                  <IntersectItems
                    targetingParams={targetingParams}
                    type={type}
                    readonly={readonly}
                    onChange={onChange}
                  />
                  {index < currentArray.length - 1 && dimensionChipLine}
                </Fragment>
              );

            default:
              return null;
          }
        })
        .filter(Boolean),
    [targetingParams, readonly, onChange, dimensionChipLine]
  );

  if (!readonly && !items.length) {
    return <EmptyFilterMessage text={t('No filters applied.')} />;
  }

  return (
    <>
      {!readonly && (
        <Box sx={{ marginTop: 2 }}>
          <Switch
            switchState={switchState}
            onSwitchChange={() => {
              onChange(prevState => ({
                ...prevState,
                [ContentObjectOptions.CONTENT_INTER_DIMENSION_OPERATOR]: switchState ? Criteria.ALL : Criteria.ANY,
              }));
            }}
            switchOffLabel={t('MATCH ALL')}
            switchOnLabel={t('MATCH ANY')}
          />
        </Box>
      )}
      {shouldDisplayAlert && <ExcludeAnyAlert />}
      {items}
    </>
  );
};
