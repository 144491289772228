import { Portal } from '@mui/base';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import { styled, css } from '@mui/material/styles';

const StyledLoaderContainerDiv = styled('div')<{
  hasPadding: boolean;
  // when fullSize is true loader fills the whole page
  fullSize?: boolean;
}>`
  text-align: center;

  ${({ hasPadding, theme }) =>
    hasPadding &&
    css`
      padding: ${theme.spacing(4)};
    `}
`;

const FullSizeLoaderBox = styled('div')`
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: absolute;
`;

interface LoaderProps extends CircularProgressProps {
  noPadding?: boolean;
  fullSize?: boolean;
}

export const Loader = (props: LoaderProps): JSX.Element => {
  const { noPadding, fullSize, ...restProps } = props;

  if (fullSize) {
    return (
      <Portal container={document.getElementById('root')}>
        <FullSizeLoaderBox>
          <CircularProgress color="primary" {...restProps} />
        </FullSizeLoaderBox>
      </Portal>
    );
  }

  return (
    <StyledLoaderContainerDiv hasPadding={!noPadding} data-test={props['data-test']}>
      <CircularProgress color="primary" {...restProps} />
    </StyledLoaderContainerDiv>
  );
};
