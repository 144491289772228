import { styled } from '@mui/material/styles';

//Replace with native mui props
export const StyledSnackbarWrapperDiv = styled('div')`
  .SnackbarContainer-root {
    bottom: 32px !important;
    gap: 12px;

    & > .MuiCollapse-root > .MuiCollapse-wrapper {
      padding: 0 !important;
    }
  }

  .notistack-CollapseWrapper > .notistack-Snackbar {
    box-shadow: 0px 0px 4px rgba(15, 15, 15, 0.16) !important;
  }

  & #notistack-snackbar {
    font-size: 13px;
    line-height: 28px;
    max-width: 570px;

    & svg {
      font-size: 18px;
      height: 28px;
      margin-inline-end: 11px;
    }
  }

  & .notistack-MuiContent-error {
    background-color: ${({ theme }) => theme.palette.error.light} !important;
    color: ${({ theme }) => theme.palette.text.primary} !important;

    & svg {
      color: ${({ theme }) => theme.palette.error.main};
    }
  }

  & .notistack-MuiContent-info {
    background-color: ${({ theme }) => theme.palette.info.light} !important;
    color: ${({ theme }) => theme.palette.text.primary} !important;

    & svg {
      color: ${({ theme }) => theme.palette.info.main};
    }
  }

  & .notistack-MuiContent-success {
    background-color: ${({ theme }) => theme.palette.success.light} !important;
    color: ${({ theme }) => theme.palette.text.primary} !important;

    & svg {
      color: ${({ theme }) => theme.palette.success.main};
    }
  }

  & .notistack-MuiContent-warning {
    background-color: ${({ theme }) => theme.palette.warning.light} !important;
    color: ${({ theme }) => theme.palette.text.primary} !important;

    & svg {
      color: ${({ theme }) => theme.palette.warning.main};
    }
  }
`;
