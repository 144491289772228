import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { GeoDataApi, GeoLocationsList, UseFetchBySearchTextProps } from '@openx/types';
import {
  buildGeographyEntitiesSearchObject,
  getGeoLocationLimit,
  searchGeoLocations,
} from '@openx/utils/state/geoLocationSearch';
import { enqueueErrorNotification } from '@openx/utils/state/notifications/actions';

export const useFetchByGeoData = (isBulk?: boolean) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const fetchByGeoData = useCallback(
    (geoData: GeoDataApi) => {
      try {
        const limit = getGeoLocationLimit(geoData);
        return searchGeoLocations(geoData, !isBulk, limit, undefined, isBulk);
      } catch (err) {
        dispatch(enqueueErrorNotification(t('Failed to fetch Geographic Locations'), err));
        return [];
      }
    },
    [dispatch, isBulk, t]
  );

  return {
    fetchByGeoData,
  };
};

export const getGeoExtendQuery = ({ excludeArea = [], includeArea = [] }: Partial<UseFetchBySearchTextProps>) => {
  let extendQuery = '';
  if (excludeArea.length) {
    extendQuery += ` AND NOT (${excludeArea.map(l => `${l.type}:"${l.name}"`).join(' OR ')})`;
  }
  if (includeArea.length) {
    extendQuery += ` AND (${includeArea.map(l => `${l.type}:"${l.name}"`).join(' OR ')})`;
  }
  return extendQuery;
};

export const useFetchBySearchText = ({
  searchText,
  excludeArea = [],
  includeArea = [],
  radius = false,
  geographicType,
}: UseFetchBySearchTextProps) => {
  const { t } = useTranslation();

  const {
    isLoading,
    data: geoSearchData,
    refetch,
  } = useQuery<GeoLocationsList>({
    meta: {
      errorMessage: t('Failed to fetch Package data'),
    },
    queryFn: () => {
      if (!searchText) {
        return [] as GeoLocationsList;
      }
      const searchObject = buildGeographyEntitiesSearchObject(`${searchText}`, radius, geographicType);

      return searchGeoLocations(searchObject, false, undefined, getGeoExtendQuery({ excludeArea, includeArea }));
    },
    queryKey: ['searchGeoLocationsByText', searchText, excludeArea, includeArea, radius],
  });

  return {
    geoSearchData,
    isLoading,
    refetch,
  };
};
