import { useMemo } from 'react';

import { VideoOption } from '@openx/types';
import {
  useVideoDurationOptionsFetch,
  useVideoFormatOptionsFetch,
  useVideoLocationOptionsFetch,
  useVideoPlacementOptionsFetch,
  extendOptionNameWithParentName,
} from '@openx/utils';

export function useVideoOptionsFetch(type: VideoOption, isUsed = true) {
  const duration = useVideoDurationOptionsFetch({ enabled: isUsed && type === VideoOption.MAX_AD_DURATION });
  const format = useVideoFormatOptionsFetch({ enabled: isUsed && type === VideoOption.PRESENTATION_FORMAT });
  const location = useVideoLocationOptionsFetch({ enabled: isUsed && type === VideoOption.IN_STREAM_VIDEO_POSITION });
  const placement = useVideoPlacementOptionsFetch({ enabled: isUsed && type === VideoOption.PLACEMENT });

  return useMemo(
    () =>
      ({
        [VideoOption.MAX_AD_DURATION]: {
          isLoading: duration.isVideoDurationLoading,
          options: duration.videoDurations,
        },
        [VideoOption.PRESENTATION_FORMAT]: {
          isLoading: format.isVideoFormatLoading,
          options: format.videoFormats,
        },
        [VideoOption.IN_STREAM_VIDEO_POSITION]: {
          isLoading: location.isVideoLocationLoading,
          options: extendOptionNameWithParentName(location.videoLocations),
        },
        [VideoOption.PLACEMENT]: {
          isLoading: placement.isVideoPlacementLoading,
          options: placement.videoPlacement,
        },
      }[type] || {}),
    [
      duration.videoDurations,
      duration.isVideoDurationLoading,
      format.videoFormats,
      format.isVideoFormatLoading,
      location.videoLocations,
      location.isVideoLocationLoading,
      placement.videoPlacement,
      placement.isVideoPlacementLoading,
      type,
    ]
  );
}
