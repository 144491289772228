import { USER_SSO_CONFIG_KEY } from '../../state/session/constants';
import { UserSSOConfig } from '../../state/session/types';

export const loadSSOConfig = (): UserSSOConfig | null => {
  const ssoConfigString = localStorage.getItem(USER_SSO_CONFIG_KEY);
  if (!ssoConfigString) {
    return null;
  }
  return JSON.parse(ssoConfigString) as UserSSOConfig;
};

export const storeSSOConfig = (config: UserSSOConfig): void => {
  if (!config) {
    return;
  }
  localStorage.setItem(USER_SSO_CONFIG_KEY, JSON.stringify(config));
};

export const removeSSOConfig = (): void => {
  localStorage.removeItem(USER_SSO_CONFIG_KEY);
};
