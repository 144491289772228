import { Error, Warning } from '@mui/icons-material';
import { Box, SxProps, Theme } from '@mui/material';

import { AlertTooltip } from '@openx/components/core';
import {
  Audience,
  AudienceStatus,
  SegmentStatus,
  audienceExpiredText,
  audienceSoonWillBeExpiredText,
  segmentExpiredText,
  segmentSoonWillBeExpired,
} from '@openx/types';
import { isSoonToDate } from '@openx/utils';

const StyledTitleBox = props => <Box sx={{ whiteSpace: 'pre-line' }} {...props} />;

interface Props {
  audience?: Audience | null;
  fromDeal?: boolean;
  sx?: SxProps<Theme>;
}

export const AudienceExpirationAlerts = ({ audience, fromDeal = false, ...restProps }: Props) => {
  const { status, estimated_expiry_date: estimatedExpiryDate, audience_segments = [] } = audience ?? {};
  const segments = audience_segments.map(({ segment }) => segment);

  const showExpiredAudienceAlert = status === AudienceStatus.EXPIRED;
  const showAudienceExpireSoonAlert = !showExpiredAudienceAlert && isSoonToDate(estimatedExpiryDate);

  const showExpiredSegmentAlert = segments.some(segment => segment.status === SegmentStatus.EXPIRED);
  const showSegmentExpireSoonAlert = segments.some(
    segment => status !== SegmentStatus.EXPIRED && isSoonToDate(segment.expiration_date)
  );

  const showExpiredAlert = showExpiredSegmentAlert || showExpiredAudienceAlert;
  const showExpireSoonAlert = showSegmentExpireSoonAlert || showAudienceExpireSoonAlert;

  const expiredText = `${showExpiredAudienceAlert ? audienceExpiredText : ''} \n\n${
    showExpiredSegmentAlert ? segmentExpiredText(fromDeal) : ''
  }`.trim();

  const soonWillBeExpiredText = `${showAudienceExpireSoonAlert ? audienceSoonWillBeExpiredText : ''} \n\n${
    showSegmentExpireSoonAlert ? segmentSoonWillBeExpired(fromDeal) : ''
  }`.trim();

  if (!showExpiredAlert && !showExpireSoonAlert) {
    return null;
  }

  return (
    <>
      {showExpiredAlert && (
        <AlertTooltip
          color="#D83158"
          title={<StyledTitleBox>{expiredText}</StyledTitleBox>}
          {...restProps}
          icon={<Error data-test="tooltip-alert-icon" fontSize="small" color="inherit" />}
        />
      )}
      {showExpireSoonAlert && (
        <AlertTooltip
          color="#E8B046"
          title={<StyledTitleBox>{soonWillBeExpiredText}</StyledTitleBox>}
          {...restProps}
          icon={<Warning data-test="tooltip-alert-icon" fontSize="small" color="inherit" />}
        />
      )}
    </>
  );
};
