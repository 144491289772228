export type PublisherAppDefaultUrlType = (typeof PublisherAppDefaultUrl)[keyof typeof PublisherAppDefaultUrl];

export const PublisherAppDefaultUrl = {
  Creatives: '/ad-quality/creatives-management/new',
  Publishers: '/inventory/publishers',
  Sites: '/inventory/sites',
} as const;

export type ApplicationDefaultUrlType = (typeof ApplicationDefaultUrl)[keyof typeof ApplicationDefaultUrl];

export const ApplicationDefaultUrl = {
  Dsp: '/dsp-ui',
  Publishers: PublisherAppDefaultUrl,
  Unity: '/unity',
} as const;

export const ApplicationPrefix = {
  Dsp: '/dsp-ui',
  Publisher: '/publisher-ui',
  Unity: '/unity',
} as const;
