import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button } from '@openx/components/core';
import type { UseAccountOptionsFetch } from '@openx/types';

import { createAudienceNavigationState } from '../utils';

type Props = {
  useAccountOptionsFetch: UseAccountOptionsFetch;
};

export const OaCreateNewAudienceButton = ({ useAccountOptionsFetch }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { getValues } = useFormContext();
  const {
    accounts: { list: account, loading: accountsLoading },
  } = useAccountOptionsFetch();

  const onCreateNewAudience = useCallback(() => {
    const formValues = getValues();
    const accountId = account?.find(account => account.uid === formValues.account_uid)?.id;

    const state = createAudienceNavigationState({ accountId, formValues, location });
    const target = '/audiences/create';
    const search = `${location.search ? '&' : '?'}fallback=true`;

    navigate(`${location.pathname}${search}`, { state });
    window.history.pushState({ key: 'default', state }, '', target);
    window.location.assign(target);
  }, [account, getValues, location, navigate]);

  return (
    <Button
      data-test="create-audience-button"
      disabled={accountsLoading}
      onClick={onCreateNewAudience}
      sx={{ marginTop: 1 }}
    >
      Create new audience
    </Button>
  );
};
