const BUFFER_TOKEN_EXPIRATION = 30_000;

export interface OIDCAuthInfo {
  accessToken: string;
  instanceUid: string;
  expirationTime: number;
}

export const OIDC_AUTH_INFO_PREFIX = 'oidc-auth-info';

export function getOIDCAuthInfoStorageKey(authority: string, clientId: string): string {
  return `${OIDC_AUTH_INFO_PREFIX}:${authority}:${clientId}`;
}

export function getExpirationTime(expiresIn: number) {
  return Date.now() + expiresIn * 1000;
}

export function loadOIDCAuthInfo(authority: string, clientId: string): OIDCAuthInfo | null {
  const storageKey = getOIDCAuthInfoStorageKey(authority, clientId);
  const oidcAuthInfoString = localStorage.getItem(storageKey);

  if (!oidcAuthInfoString) {
    return null;
  }

  return JSON.parse(oidcAuthInfoString) as OIDCAuthInfo;
}

export function storeOIDCAuthInfo(authority: string, clientId: string, info: OIDCAuthInfo): void {
  const storageKey = getOIDCAuthInfoStorageKey(authority, clientId);
  localStorage.setItem(storageKey, JSON.stringify(info));
}

export function isOIDCAuthInfoExpired(info: OIDCAuthInfo | null): info is null {
  if (!info) {
    return true;
  }

  const { expirationTime } = info;

  if (Date.now() > expirationTime - BUFFER_TOKEN_EXPIRATION) {
    return true;
  }

  return false;
}
