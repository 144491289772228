import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Box } from '@mui/material';
import { useMemo } from 'react';

import { Ellipsis, HighlightedPhrase, theme } from '@openx/components/core';
import { type AllowBlock, AllowBlockType, type OptionsMap } from '@openx/types';

import { getPossibleActions } from './utils';

type Props = {
  optionsMap: OptionsMap;
  optionId: string;
  rule: AllowBlockType | null;
  search: string;
  selectedItems: AllowBlock;
  handleAllowAction: (optionId: string, canUnselect: boolean) => void;
  handleBlockAction: (optionId: string, canUnselect: boolean) => void;
};

export const HierarchyOptionDropdownItem = ({
  optionsMap,
  optionId,
  search,
  selectedItems,
  rule,
  handleAllowAction,
  handleBlockAction,
}: Props) => {
  const option = optionsMap[optionId];

  const isOptionSelected = (key: 'allow' | 'block') => selectedItems[key].includes(option.name);

  const isAllowing = isOptionSelected('allow');
  const isBlocking = isOptionSelected('block');

  const { canAllow, canBlock, canUnselect } = useMemo(
    () =>
      getPossibleActions({ isSelected: isAllowing || isBlocking, optionId, options: optionsMap, rule, selectedItems }),
    [optionId, optionsMap, rule, selectedItems, isAllowing, isBlocking]
  );

  return (
    <Box
      sx={{
        ':hover': {
          cursor: 'default',
        },
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: 0.8,
        paddingLeft: ((option?.level ?? 1) - 1) * 2 + 1.5,
        paddingRight: 1.5,
        paddingTop: 0.8,
        width: '100%',
      }}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <Ellipsis width="85%" tooltip>
        <HighlightedPhrase searchPhrase={search}>{option.name}</HighlightedPhrase>
      </Ellipsis>

      <Box sx={{ display: 'flex', gap: 1 }}>
        <AddCircleOutlineIcon
          data-test={`hierarchy-allow-item-${option.name.toLowerCase()}`}
          data-selected={isAllowing}
          field-block={canAllow ? 'False' : 'True'}
          onClick={() => canAllow && handleAllowAction(optionId, canUnselect)}
          sx={{
            ':hover': { cursor: canAllow ? 'pointer' : 'default' },
            color: isAllowing ? theme.palette.success.main : theme.palette.text.disabled,
            opacity: canAllow ? 1 : 0.5,
            width: '18px',
          }}
        />

        <RemoveCircleOutlineIcon
          data-test={`hierarchy-block-item-${option.name.toLowerCase()}`}
          data-selected={isBlocking}
          field-block={canAllow ? 'False' : 'True'}
          onClick={() => canBlock && handleBlockAction(optionId, canUnselect)}
          sx={{
            ':hover': { cursor: canBlock ? 'pointer' : 'default' },
            color: isBlocking ? theme.palette.error.main : theme.palette.text.disabled,
            opacity: canBlock ? 1 : 0.5,
            width: '18px',
          }}
        />
      </Box>
    </Box>
  );
};
