export const FETCHING_AUTH_READY = 'FETCHING_AUTH_READY';
export const SENDING_SESSION_DELETE = 'SENDING_SESSION_DELETE';
export const FETCHING_AUTH_READY_SUCCESS = 'FETCHING_AUTH_READY_SUCCESS';
export const SEND_DELETE_SESSION_SUCCESS = 'SEND_DELETE_SESSION_SUCCESS';
export const FETCHING_AUTH_READY_ERROR = 'FETCHING_AUTH_READY_ERROR';
export const SEND_DELETE_SESSION_ERROR = 'SEND_DELETE_SESSION_ERROR';
export const SENDING_PUT_SESSION_ERROR = 'SENDING_PUT_SESSION_ERROR';
export const FETCHING_SESSION_ERROR = 'FETCHING_SESSION_ERROR';
export const SESSION_CHECK_SUCCESS = 'SESSION_CHECK_SUCCESS';
export const CHANGE_USER_LOCALE = 'CHANGE_USER_LOCALE';
export const CHANGE_ACCOUNT_MARKET = 'CHANGE_ACCOUNT_MARKET';

export const INTERNAL_USER_SESSION_LOCALSTORAGE_KEY = 'user';
export const DISPATCHER_USER_SESSION_LOCALSTORAGE_KEY = 'dispatcher-user';
export const PUBLISHER_USER_SESSION_LOCALSTORAGE_KEY = 'publisher-ui-user';
export const DSP_USER_SESSION_LOCALSTORAGE_KEY = 'dsp-ui-user';
export const WORK_AS_USER_SESSION_LOCALSTORAGE_KEY = 'workAsUser';
export const WORK_AS_ENDED_KEY = 'workAsEnded';
export const USER_LOGIN_TYPE_KEY = 'user-login-type';
export const USER_SSO_CONFIG_KEY = 'user-sso-config';
export const UNITY_GO_BACK_URL_KEY = 'unityGoBackUrl';

export const FEDERATION_GRANT_TYPE = 'urn:ietf:params:oauth:grant-type:token-exchange';
export const FEDERATION_REQUESTED_TOKEN_TYPE = 'urn:ietf:params:oauth:token-type:access_token';
export const FEDERATION_SUBJECT_TOKEN_TYPE = 'urn:ietf:params:oauth:token-type:id_token';
export const FEDERATION_ISSUED_TOKEN_TYPE = 'urn:ietf:params:oauth:token-type:access_token';
export const FEDERATION_TOKEN_TYPE = 'Bearer';
