import { StateUtil } from './stateUtil';

export enum OptionsType {
  USER_ROLES = 'userRoles',
  LOCALES = 'locales',
  STATUSES = 'statuses',
  REPORT_RANGES = 'reportRanges',
  EXPERIENCES = 'experiences',
  AD_CATEGORIES = 'adCategories',
  TARGETABLE_DEMAND_CRITERIA = 'targetableDemandCriteria',
  BUYERS = 'buyers',
  ACCOUNT_PARTNERS = 'accountPartners',
  PARTNER_REGIONS = 'partnerRegions',
  COUNTRIES = 'countries',
  MARKET_BRAND_GROUPS = 'marketBrandGroups',
  DOMAIN_CATEGORIES = 'domainCategories',
  DOMAIN_AUDIENCE_TYPES = 'domainAudienceTypes',
  DOMAIN_BAN_REASONS = 'domainBanReasons',
  CURRENCIES = 'currencies',
  ACCOUNTS = 'accounts',
  GENRE = 'genre',
  NETWORK_ACCOUNTS = 'networkAccounts',
  ALL_DEMAND_PARTNERS = 'allDemandPartners',
  PMP_DEAL_TYPE = 'pmpDealType',
  DEAL_SOURCE = 'dealSource',
  TARGETABLE_DEMAND_PARTNER = 'targetableDemandPartner',
  NETWORK_N_PUBLISHERS_ACCOUNTS = 'networkAndPublisherAccounts',
  PUBLISHERS_ACCOUNTS = 'publisherAccounts',
  ACTIVE_PUBLISHERS_ACCOUNTS = 'activePublisherAccounts',
  OPEN_AUCTION_ACCESS = 'openAuctionAccess',
  CONTENT_ATTRIBUTE = 'contentAttributes',
  CREATIVE_TYPES = 'creativeTypes',
  LANGUAGE = 'language',
  DEVICE_TYPE = 'device_type',
  OS = 'os',
  PMP_SIZE = 'pmpSize',
  APP_CATEGORIES_BAN_REASONS = 'appCategoriesBanReasons',
  APP_APPINFO_ORIGINS = 'appAppInfoOrigins',
  APP_CATEGORIES_PLATFORMS = 'appCategoriesPlatforms',
  VIDEO_DURATION = 'linearvideoDuration',
  VIDEO_FORMAT = 'linearvideoPresentationFormat',
  VIDEO_LOCATION = 'linearvideoLocation',
  VIDEO_PLACEMENT = 'videoPlacement',
  VIEWABILITY = 'viewability',
  AUDIENCE_ACCOUNTS = 'audienceAccounts',
  VIDEO_API_FRAMEWORK = 'videoApiFramework',
  BROWSER = 'browsers',
  TARGETING_LANGUAGE = 'targetingLanguages',
  SIZES = 'sizes',
  MOBILE_PRESENTATION_FORMAT = 'mobilePresentationFormat',
  MOBILE_API_FRAMEWORK = 'mobileApiFramework',
  LINEARVIDEO_SIZE = 'linearvideoSize',
  ADUNIT_VIDEO_MIME_TYPE = 'adunitVideoMimeType',
  PLAYBACKMETHOD = 'playbackmethod',
  VIDEO_PROTOCOL = 'videoProtocol',
  VIEW_THROUGH_RATE = 'vtr_score_options',
  SUBTYPE_NAMES = 'subtypeNames',
  MOBILE_LOCATION = 'mobileLocation',
  WEB_LOCATION = 'webLocation',
  CONTENT_TYPE = 'contentType',
  CONTENT_TOPIC = 'contentTopic',
  DEAL_STATUS = 'dealStatus',
  DEAL_TYPE = 'dealType',
  EMAIL_LOCATION = 'emailLocation',
  VIDEO_COMPANION_LOCATION = 'videoCompanionLocation',
  NON_LINEARVIDEO_LOCATION = 'nonLinearvideoLocation',
  EXT_SUPPLY_PARTNER = 'extSupplyPartner',
  PARTNER_TYPE = 'partnerType',
  DISTRIBUTION_CHANNEL = 'distributionChannel',
  AD_PLACEMENT = 'adPlacement',
  OPTIMIZATION_ACCOUNTS = 'optimizationAccounts',
  MARKET_DELIVERY_MEDIUM = 'marketDeliveryMedium',
  FEE_REASON_OPTIONS = 'feeReasonOptions',
  MARKET_OPERATORS = 'marketOperators',
  MARKET_ADVERTISERS = 'markerAdvertisers',
  MARKET_BRANDS = 'marketBrands',
  SSRTB_METRICS = 'ssrtbMetrics',
  SSRTB_ENRICHMENT_FEATURES = 'ssrtbEnrichmentFeatures',
  SSRTB_PREFERRED_TAXONOMY = 'ssrtbPreferredTaxonomy',
  TIMEZONE = 'timezoneOptions',
  TRAFFIC_FILTER_EIDS = 'trafficFilterEids',
  COMPRESSION = 'compression',
  DATA_CENTER = 'dataCenter',
  SEAT_REGIONS = 'seatRegions',
  METACATEGORIES = 'metacategories',
  A_DOMAINS = 'adomains',
  EXCHANGE_SIZE_OPTIONS = 'exchangeSizeOptions',
  CONTENT_RATING = 'contentrating',
  RTB_CONTENT_FORWARDING_DSP = 'rtb_content_forwarding_dsp',
  RTB_CONTENT_FORWARDING_OTHER_DSPS = 'rtb_content_forwarding_other_dsps',
  RTB_FORWARDING_POLICY_ALLOWED_SIGNALS = 'rtb_forwarding_policy_allowed_signals',
  SITES_WITH_ACCOUNT_INFO = 'sitesWithAccountInfo',
  LOCATION_SOURCE_OPTIONS = 'locationSourceOptions',
  CONNECTION_SPEED = 'connectionSpeed',
  CONNECTION_TYPE = 'connectionType',
  DEVICE = 'device',
  SCREEN_RESOLUTION = 'screenResolution',
  MOBILE_CARRIER = 'mobileCarrier',
  DEAL_AD_CATEGORY = 'dealAdCategory',
}

export enum ParameterizedOptionsType {
  DEMAND_PARTNER_BUYER_SEATS = 'demandPartnerBuyerSeats',
  DEMAND_PARTNER_BUYER = 'demandPartnerBuyer',
  TARGETABLE_PACKAGE = 'targetablePackage',
  THIRD_PARTY_PARTNERS = 'thirdPartyPartners',
  PUBLISHERS = 'publishers',
  DMP_SEGMENTS = 'dmp_segments',
  OPEN_AUDIENCES = 'openaudience_custom',
  ACCOUNT_EXECUTIVE = 'accountExecutive',
  ACCOUNT_MANAGER = 'accountManager',
  PUBLISHERS_BY_INSTANCE = 'publishersByInstance',
  ORDER = 'order',
}

export type BaseOption = {
  id: string;
  name: string;
};

export interface Option extends BaseOption {
  status?: string;
  parent?: string;
  parent_name?: string;
  sort_id?: number;
  parent_id?: string | null;
  acl?: string;
  third_party_buyer_id?: string[];
  default_share?: number;
  cat_level?: number | string;
  level?: number;
  standard?: string;
  ancestors_names?: string[];
  instance_name?: string;
  adomain?: string;
  accounts?: {
    id: string;
    name: string;
    timestamp: string;
  }[];
  legacy_id?: string | number;
  object?: string;
}

export interface BuyerOption {
  id: string;
  name: string;
  buyer_id: string;
  seat_id?: string;
}

export interface AccountOption extends BaseOption {
  object: string;
}

export interface UserRoleOption extends BaseOption {
  description?: string;
  acl?: string;
}

export type OptionsMap = Record<string, Option>;

export type OptionsList = Option[];

export type Standard = 'allowed' | 'blocked';

export type CreativeTypeOptionsApi = {
  standard: Standard;
} & BaseOption;

export type ContentAttributeOptionsApi = {
  standard: Standard;
} & BaseOption;

export type BrandGroupOptionsApi = {
  standard: Standard;
  parent_group_name: string;
} & BaseOption;

export type MarketAdvertiserOptionsApi = {
  systemregion_id: string;
  account_id: string;
  uid: string;
} & BaseOption;

export type OptionStateMap<o extends BaseOption = Option> = Omit<StateUtil<o>, 'list'>;
export type OptionStateList<o extends BaseOption = Option> = Omit<StateUtil<o>, 'mapById'>;

export type OptionState<o extends BaseOption = Option> = StateUtil<o>;

export type OptionsState = Record<OptionsType, OptionState>;

export const mapJsonToOptions = (jsonArray: any[]) =>
  jsonArray.map(option => ({ ...option, id: option.id.toString() } as Option));
