import CloseIcon from '@mui/icons-material/Close';
import { Grid, IconButton, Paper, Snackbar, styled, Typography } from '@mui/material';
import { MouseEvent, ReactNode, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DebounceField } from '@openx/components/core/DebounceField';
import type { ChipFilterValue, FiltersConfig } from '@openx/types';

import { ChipFiltersView } from './ChipFiltersView';

const StyledContainerGrid = styled(Grid)`
  background: ${({ theme }) => theme.palette.background.boxDark};
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  border-top: 1px solid ${({ theme }) => theme.palette.divider};
  min-height: 48px;
  padding: ${({ theme }) => theme.spacing(1, 4, 0, 4)};
  position: relative;
`;

const StyledCloseIconButton = styled(IconButton)`
  position: absolute;
  right: 8px;
`;

const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.disabled};
  padding: ${({ theme }) => theme.spacing(1.25, 1.5)};
`;

const StyledSnackbar = styled(Snackbar)`
  & {
    position: absolute;
    bottom: auto;
    left: auto;
    top: 41px;
  }
`;

interface TopBarProps<FilterType extends { phrase: string }> {
  filters: FilterType;
  onChange: (filters: FilterType) => void;
  searchText?: string;
  chipFiltersConfig?: FiltersConfig;
  minLength?: number;
  customPopoverContent?: ReactNode;
  onPhraseChange?: (text: string) => void;
  autofocus?: boolean;
  fullWidth?: boolean;
}

export function TopBar<FilterType extends { phrase: string }>({
  filters,
  onChange,
  onPhraseChange,
  searchText,
  chipFiltersConfig,
  minLength = 2,
  customPopoverContent,
  autofocus = true,
  fullWidth = false,
}: TopBarProps<FilterType>) {
  const { t } = useTranslation();

  const [searchBarAnchor, setSearchBarAnchor] = useState<null | HTMLElement>(null);
  const [textLength, setTextLength] = useState(0);

  const isPopperOpen = Boolean(searchBarAnchor);

  const clearSearchValue = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onPhraseChange ? onPhraseChange('') : onChange({ ...filters, phrase: '' });
  };

  const handleClick = useCallback((event: MouseEvent<HTMLDivElement>) => {
    const input: null | HTMLInputElement = event.currentTarget.getElementsByTagName('input').item(0);

    setSearchBarAnchor(event.currentTarget);
    setTextLength(input?.value.length ?? 0);
    event.stopPropagation();
  }, []);

  const hidePopper = useCallback(() => setSearchBarAnchor(null), []);

  const onFilterChange = (newFilters: { [categoryName: string]: ChipFilterValue }) => {
    onChange({ ...filters, ...newFilters });
  };

  const phraseChangeHandler = useCallback(
    event => {
      const searchPhraseLength = event.target.value.length;

      setTextLength(searchPhraseLength);

      if (searchPhraseLength > minLength || !searchPhraseLength) {
        onPhraseChange ? onPhraseChange(event.target.value) : onChange({ ...filters, phrase: event.target.value });
      }
    },
    [filters, minLength, onChange, onPhraseChange]
  );

  const preventEventPropagation = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  useEffect(() => {
    document.addEventListener('click', hidePopper);

    return () => {
      document.removeEventListener('click', hidePopper);
    };
  }, [hidePopper]);

  return (
    <StyledContainerGrid container>
      {searchText && (
        <Grid container item xs={fullWidth ? 12 : 4} alignItems="flex-start">
          <Grid container>
            <DebounceField
              placeholder={searchText}
              type="search"
              autoFocus={autofocus}
              onChange={phraseChangeHandler}
              onClick={handleClick}
              value={filters.phrase}
              fullWidth
              debounceTimeout={500}
              data-test="search-field"
              InputProps={{
                disableUnderline: true,
                endAdornment: filters.phrase && (
                  <StyledCloseIconButton data-test="close-search" size="small" onClick={clearSearchValue}>
                    <CloseIcon />
                  </StyledCloseIconButton>
                ),
              }}
            />

            <StyledSnackbar onClick={preventEventPropagation} open={isPopperOpen}>
              <Paper sx={{ width: searchBarAnchor?.offsetWidth + 'px' }}>
                {textLength <= minLength && (
                  <StyledTypography data-test="search-placeholder" variant="body1">
                    {t('Provide at least {count} characters', { count: minLength + 1 })}
                  </StyledTypography>
                )}
                {customPopoverContent}
              </Paper>
            </StyledSnackbar>
          </Grid>
        </Grid>
      )}
      {chipFiltersConfig && (
        <Grid item xs={8}>
          <ChipFiltersView filtersConfig={chipFiltersConfig} filters={filters} onFilterChange={onFilterChange} />
        </Grid>
      )}
    </StyledContainerGrid>
  );
}
