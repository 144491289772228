import { TextField as MuiTextField } from '@mui/material';
import { ChangeEvent, useCallback } from 'react';
import { useController } from 'react-hook-form';

import { DebounceInput } from './DebounceRHFTextField';
import type { MuiTextFieldProps, RHFTextFieldProps } from './type';

export const RHFTextField = <T extends Record<string, unknown> = Record<string, string>>({
  useDebounce,
  ...rest
}: RHFTextFieldProps<T>): JSX.Element => {
  if (useDebounce) return <DebounceInput {...rest} />;
  return <NormalTextField {...rest} />;
};

const NormalTextField = <T extends Record<string, unknown> = Record<string, string>>({
  name,
  control,
  FormHelperTextProps,
  rules,
  onChange,
  ...rest
}: RHFTextFieldProps<T>): JSX.Element => {
  const {
    field: { onChange: fieldOnChange, ...restFieldProps },
    fieldState: { invalid, error },
    formState: { isSubmitting },
  } = useController({
    control,
    name,
    rules: rules,
  });

  const onInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onChange ? onChange(e) : fieldOnChange(e);
    },
    [fieldOnChange, onChange]
  );

  const defaultProps: MuiTextFieldProps = {
    InputLabelProps: { disableAnimation: true, focused: false, shrink: true },
    InputProps: { disableUnderline: true },
    'data-test': rest['data-test'],
    variant: 'standard',
  };

  return (
    <MuiTextField
      {...defaultProps}
      {...restFieldProps}
      {...rest}
      FormHelperTextProps={FormHelperTextProps}
      disabled={isSubmitting || rest.disabled}
      onChange={onInputChange}
      error={invalid || rest.error}
      helperText={error?.message ?? rest.helperText}
    />
  );
};
