import ReactGA from 'react-ga4';

export const initGA = (gaTrackingId: string, testMode: boolean) => {
  ReactGA.initialize(gaTrackingId, { testMode });
};

export const trackCreateDealConversion = () => {
  ReactGA.event('Create Deal');
};

export const trackConfirmationConversion = () => {
  ReactGA.event('Landing on Confirmation');
};

export const trackCreativesManagementVisitConversion = () => {
  ReactGA.event('Landing on Creatives Management');
};

export const trackBackToGoogleConversion = () => {
  ReactGA.event('Back to Google');
};

export const trackPageView = (page: string) => {
  ReactGA.send({ hitType: 'pageview', page });
};
