import { getEnvVariables } from '../services';

export const getXOpenxInstance = () => {
  const { hostname } = window.location;
  const { ssoAuthority } = getEnvVariables();

  if (hostname === 'localhost') {
    const env = ssoAuthority ? new URL(ssoAuthority).hostname.split('.')[0] : 'devint';

    return `ui.${env}.gcp.openx.org`;
  }

  return hostname;
};
