import { ComparisonMap, ComparisonType, CriteriaValue, Intersect } from './targetingValuesTypes';

export enum TechnologyAndDevicesOption {
  API_FRAMEWORK = 'api_framework',
  BROWSER = 'browser',
  CONNECTION_SPEED = 'connection_speed',
  CONNECTION_TYPE = 'connection_type',
  DEVICE = 'device',
  DEVICE_ID = 'device_id',
  DEVICE_TYPE = 'device_type',
  HTTPS = 'https',
  MOBILE_CARRIER = 'isp_carrier',
  LANGUAGE = 'language',
  MKT_COOKIES = 'mkt_cookies',
  OS = 'os',
  SCREEN_RESOLUTION = 'screen_resolution',
  USER_AGENT = 'user_agent',
}

export const technologyAndDevicesOptions = [
  TechnologyAndDevicesOption.API_FRAMEWORK,
  TechnologyAndDevicesOption.BROWSER,
  TechnologyAndDevicesOption.CONNECTION_SPEED,
  TechnologyAndDevicesOption.CONNECTION_TYPE,
  TechnologyAndDevicesOption.DEVICE,
  TechnologyAndDevicesOption.DEVICE_ID,
  TechnologyAndDevicesOption.DEVICE_TYPE,
  TechnologyAndDevicesOption.HTTPS,
  TechnologyAndDevicesOption.MOBILE_CARRIER,
  TechnologyAndDevicesOption.LANGUAGE,
  TechnologyAndDevicesOption.MKT_COOKIES,
  TechnologyAndDevicesOption.OS,
  TechnologyAndDevicesOption.SCREEN_RESOLUTION,
  TechnologyAndDevicesOption.USER_AGENT,
];

export interface BrowserOption {
  browser_id: number;
  browser_name: string;
  id: number;
  name: string;
  version_id: string;
  version_name: string;
}

export interface TechnologyAndDevicesData {
  [TechnologyAndDevicesOption.API_FRAMEWORK]?: DataPayload | null;
  [TechnologyAndDevicesOption.BROWSER]?: DataPayload | null;
  [TechnologyAndDevicesOption.CONNECTION_SPEED]?: DataPayload | null;
  [TechnologyAndDevicesOption.CONNECTION_TYPE]?: DataPayload | null;
  [TechnologyAndDevicesOption.DEVICE]?: DataPayload | null;
  [TechnologyAndDevicesOption.DEVICE_ID]?: DataPayload | null;
  [TechnologyAndDevicesOption.DEVICE_TYPE]?: DataPayload | null;
  [TechnologyAndDevicesOption.HTTPS]?: DataPayload | null;
  [TechnologyAndDevicesOption.MOBILE_CARRIER]?: DataPayload | null;
  [TechnologyAndDevicesOption.LANGUAGE]?: DataPayload | null;
  [TechnologyAndDevicesOption.MKT_COOKIES]?: DataPayload | null;
  [TechnologyAndDevicesOption.OS]?: DataPayload | null;
  [TechnologyAndDevicesOption.SCREEN_RESOLUTION]?: DataPayload | null;
  [TechnologyAndDevicesOption.USER_AGENT]?: UserAgentPayload | null;
}

export interface TechnologyAndDevicesFormState {
  [TechnologyAndDevicesOption.API_FRAMEWORK]: StatePayload;
  [TechnologyAndDevicesOption.BROWSER]: StatePayload;
  [TechnologyAndDevicesOption.CONNECTION_SPEED]: StatePayload;
  [TechnologyAndDevicesOption.CONNECTION_TYPE]: StatePayload;
  [TechnologyAndDevicesOption.DEVICE]: StatePayload;
  [TechnologyAndDevicesOption.DEVICE_ID]: StatePayload;
  [TechnologyAndDevicesOption.DEVICE_TYPE]: StatePayload;
  [TechnologyAndDevicesOption.HTTPS]: StatePayload;
  [TechnologyAndDevicesOption.MOBILE_CARRIER]: StatePayload;
  [TechnologyAndDevicesOption.LANGUAGE]: StatePayload;
  [TechnologyAndDevicesOption.MKT_COOKIES]: StatePayload;
  [TechnologyAndDevicesOption.OS]: StatePayload;
  [TechnologyAndDevicesOption.SCREEN_RESOLUTION]: StatePayload;
  [TechnologyAndDevicesOption.USER_AGENT]: UserAgentPayload;
}

type StatePayload = {
  op: Intersect | ComparisonType;
  val: Set<string>;
};

export type UserAgentPayload = {
  op: CriteriaValue;
  val: ComparisonMap;
};

export type DataPayload = {
  op: Intersect | ComparisonType;
  val: string;
};

export type UseTechnologyOptionsFetchType = (
  type: TechnologyAndDevicesOption,
  isUsed?: boolean
) => {
  isLoading: boolean;
  options: any;
};
