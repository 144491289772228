import { useMemo } from 'react';

import { TechnologyAndDevicesOption, UseTechnologyOptionsFetchType } from '@openx/types';
import {
  useDeviceTypesOptionsFetch,
  useOsOptionsFetch,
  useAPiFrameworksOptionsFetch,
  useBrowserOptionsFetch,
  useTargetingLanguagesOptionsFetch,
  useConnectionSpeedOptionsFetch,
  useConnectionTypeOptionsFetch,
  useDeviceOptionsFetch,
  useScreenResolutionOptionsFetch,
  useMobileCarrierOptionsFetch,
} from '@openx/utils';

import { MobileCarrierOptions } from '../../Targeting/TechnologyAndDevices/constants';

export const useTechnologyOptionsFetch: UseTechnologyOptionsFetchType = (
  type: TechnologyAndDevicesOption,
  isUsed = true
) => {
  // isUsed + type check needed to prevent fetch of options when option is not used
  const deviceTypes = useDeviceTypesOptionsFetch({
    enabled: isUsed && type === TechnologyAndDevicesOption.DEVICE_TYPE,
  });

  const os = useOsOptionsFetch({ enabled: isUsed && type === TechnologyAndDevicesOption.OS });

  const apiFrameworks = useAPiFrameworksOptionsFetch({
    enabled: isUsed && type === TechnologyAndDevicesOption.API_FRAMEWORK,
  });

  const browsers = useBrowserOptionsFetch({ enabled: isUsed && type === TechnologyAndDevicesOption.BROWSER });

  const languages = useTargetingLanguagesOptionsFetch({
    enabled: isUsed && type === TechnologyAndDevicesOption.LANGUAGE,
  });

  const connectionSpeed = useConnectionSpeedOptionsFetch({
    enabled: isUsed && type === TechnologyAndDevicesOption.CONNECTION_SPEED,
  });

  const connectionType = useConnectionTypeOptionsFetch({
    enabled: isUsed && type === TechnologyAndDevicesOption.CONNECTION_TYPE,
  });

  const devices = useDeviceOptionsFetch({
    enabled: isUsed && type === TechnologyAndDevicesOption.DEVICE,
  });

  const screenResolutions = useScreenResolutionOptionsFetch({
    enabled: isUsed && type === TechnologyAndDevicesOption.SCREEN_RESOLUTION,
  });

  const mobileCarrierOptions = useMobileCarrierOptionsFetch({
    enabled: isUsed && type === TechnologyAndDevicesOption.MOBILE_CARRIER,
  });

  return useMemo(() => {
    if (isUsed) {
      return {
        [TechnologyAndDevicesOption.DEVICE_TYPE]: {
          isLoading: deviceTypes.isDeviceTypesLoading,
          options: deviceTypes.deviceTypes,
        },
        [TechnologyAndDevicesOption.OS]: {
          isLoading: os.isOsLoading,
          options: os.os,
        },
        [TechnologyAndDevicesOption.BROWSER]: {
          isLoading: browsers.isBrowserOptionsLoading,
          options: browsers.browsers,
        },
        [TechnologyAndDevicesOption.LANGUAGE]: {
          isLoading: languages.isTargetingLanguagesLoading,
          options: languages.targetingLanguages,
        },
        [TechnologyAndDevicesOption.API_FRAMEWORK]: {
          isLoading: apiFrameworks.isApiFrameworksLoading,
          options: apiFrameworks.apiFrameworks,
        },
        [TechnologyAndDevicesOption.CONNECTION_SPEED]: {
          isLoading: connectionSpeed.isConnectionSpeedLoading,
          options: connectionSpeed.connectionSpeedOptions,
        },
        [TechnologyAndDevicesOption.CONNECTION_TYPE]: {
          isLoading: connectionType.isConnectionTypeLoading,
          options: connectionType.connectionTypeOptions,
        },
        [TechnologyAndDevicesOption.DEVICE]: {
          isLoading: devices.isDeviceOptionsLoading,
          options: devices.deviceOptions,
        },
        [TechnologyAndDevicesOption.SCREEN_RESOLUTION]: {
          isLoading: screenResolutions.isScreenResolutionOptionsLoading,
          options: screenResolutions.screenResolutionOptions,
        },
        [TechnologyAndDevicesOption.MOBILE_CARRIER]: {
          isLoading: mobileCarrierOptions.isMobileCarrierOptionsLoading,
          // 'NO_VALUE' is an additional option not included in API Options that has be handled manually
          options: {
            ...mobileCarrierOptions.mobileCarrierOptions,
            [MobileCarrierOptions.isWifiOnly]: { id: MobileCarrierOptions.isWifiOnly, name: 'Is WiFi only' },
          },
        },
      }[type];
    }

    return {};
  }, [
    apiFrameworks.apiFrameworks,
    apiFrameworks.isApiFrameworksLoading,
    browsers.browsers,
    browsers.isBrowserOptionsLoading,
    connectionSpeed,
    connectionType,
    devices,
    deviceTypes.deviceTypes,
    deviceTypes.isDeviceTypesLoading,
    isUsed,
    languages.isTargetingLanguagesLoading,
    languages.targetingLanguages,
    mobileCarrierOptions,
    os.isOsLoading,
    os.os,
    screenResolutions,
    type,
  ]);
};
