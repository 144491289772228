import { CircularProgress, Tooltip } from '@mui/material';
import { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { useMemo } from 'react';

import {
  StyledButton,
  StyledHiddenLabelSpan,
  StyledIconButton,
  StyledProgressLayerContainerDiv,
} from './Button.styled';

export interface ButtonProps extends Omit<MuiButtonProps, 'color'> {
  color?: MuiButtonProps['color'] | 'default';
  icon?: boolean;
  loading?: boolean;
  allowed?: boolean;
  notAllowedMessage?: string;
  'data-test'?: string | ((row: any) => string);
}

export function ButtonAction({
  variant = 'contained',
  size = 'medium',
  color = 'default',
  loading = false,
  disabled = false,
  allowed = true,
  icon = false,
  'data-test': dataTest = 'button',
  notAllowedMessage = 'You need permission to perform this action',
  children,
  onClick,
  ...restProps
}: ButtonProps): JSX.Element {
  const circularProgress = useMemo(() => {
    const CircularProgressColor: Record<NonNullable<ButtonProps['variant']>, string> = {
      contained: 'inherit',
      outlined: color,
      text: color,
    };

    const CircularProgressColorSize: Record<NonNullable<MuiButtonProps['size']>, number> = {
      large: 18,
      medium: 16,
      small: 14,
    };

    return (
      <StyledProgressLayerContainerDiv>
        <CircularProgress
          color={CircularProgressColor[variant] as MuiButtonProps['color']}
          size={CircularProgressColorSize[size]}
          data-test="button-circular-progress"
        />
      </StyledProgressLayerContainerDiv>
    );
  }, [color, variant, size]);

  const label = useMemo(() => {
    if (loading) {
      return <StyledHiddenLabelSpan>{children}</StyledHiddenLabelSpan>;
    }
    return children;
  }, [loading, children]);

  const ButtonComponent = (icon ? StyledIconButton : StyledButton) as React.ElementType;

  if (!allowed) {
    return (
      <Tooltip title={notAllowedMessage}>
        <span>
          <ButtonComponent variant={variant} size={size} data-test={dataTest} {...restProps} disabled>
            {children}
          </ButtonComponent>
        </span>
      </Tooltip>
    );
  }

  return (
    <ButtonComponent
      variant={variant}
      color={color}
      size={size}
      disabled={disabled}
      loading={loading}
      disableRipple={variant === 'text'}
      data-test={dataTest}
      onClick={onClick}
      {...restProps}
    >
      {label}
      {loading && circularProgress}
    </ButtonComponent>
  );
}
