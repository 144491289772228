import { Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const ExcludeAnyAlert = () => {
  const { t } = useTranslation();

  return (
    <Alert severity="warning" data-test="cot-exclude-warning" sx={{ marginTop: 3, maxWidth: '640px' }}>
      {t('Using OR when excluding multiple values is logically equivalent to including all possible values.')}
    </Alert>
  );
};
