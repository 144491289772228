export const IconChecked = (): JSX.Element => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    style={{ clipRule: 'evenodd', fillRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}
  >
    <g id="checked">
      <g transform="matrix(2.28571,0,0,2.28571,-13.7143,-9.14286)">
        <path
          d="M13,4.875C13,4.392 12.608,4 12.125,4L6.875,4C6.392,4 6,4.392 6,4.875L6,10.125C6,10.608 6.392,11 6.875,11L12.125,11C12.608,11 13,10.608 13,10.125L13,4.875Z"
          fill="rgb(0,118,189)"
        />
      </g>
      <g transform="matrix(1,0,0,1,3.5,4.5)">
        <path d="M7.826,0L9,1.235L3.53,7L0,3.294L1.174,2.059L3.53,4.529L7.826,0Z" fill="white" />
      </g>
    </g>
  </svg>
);

type IconUncheckedProps = {
  color: string;
};

export const IconUnchecked = ({ color }: IconUncheckedProps): JSX.Element => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    style={{ clipRule: 'evenodd', fillRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}
  >
    <g id="checkbox">
      <g transform="matrix(2.28571,0,0,2.28571,-13.7143,-9.14286)">
        <path
          d="M13,4.875C13,4.392 12.608,4 12.125,4L6.875,4C6.392,4 6,4.392 6,4.875L6,10.125C6,10.608 6.392,11 6.875,11L12.125,11C12.608,11 13,10.608 13,10.125L13,4.875Z"
          fill="white"
        />
      </g>
      <g transform="matrix(2.28571,0,0,2.28571,-13.7143,-9.14286)">
        <path
          d="M13,4.875C13,4.392 12.608,4 12.125,4L6.875,4C6.392,4 6,4.392 6,4.875L6,10.125C6,10.608 6.392,11 6.875,11L12.125,11C12.608,11 13,10.608 13,10.125L13,4.875ZM12.563,4.875L12.563,10.125C12.563,10.366 12.366,10.563 12.125,10.563L6.875,10.563C6.634,10.563 6.438,10.366 6.438,10.125L6.438,4.875C6.438,4.634 6.634,4.438 6.875,4.438L12.125,4.438C12.366,4.438 12.563,4.634 12.563,4.875Z"
          fill={color}
        />
      </g>
    </g>
  </svg>
);

export const IconIndeterminate = (): JSX.Element => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    style={{ clipRule: 'evenodd', fillRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}
  >
    <g id="Indeterminate">
      <g transform="matrix(2.28571,0,0,2.28571,-13.7143,-9.14286)">
        <path
          d="M13,4.875C13,4.392 12.608,4 12.125,4L6.875,4C6.392,4 6,4.392 6,4.875L6,10.125C6,10.608 6.392,11 6.875,11L12.125,11C12.608,11 13,10.608 13,10.125L13,4.875Z"
          fill="rgb(0,118,189)"
        />
      </g>
      <g transform="matrix(1,0,0,1,4,7)">
        <rect x="0" y="0" width="8" height="2" fill="white" />
      </g>
    </g>
  </svg>
);

export const IconUncheckedDisabled = (): JSX.Element => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    style={{ clipRule: 'evenodd', fillRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}
  >
    <g id="disabled">
      <g transform="matrix(2.28571,0,0,2.28571,-13.7143,-9.14286)">
        <path
          d="M13,4.875C13,4.392 12.608,4 12.125,4L6.875,4C6.392,4 6,4.392 6,4.875L6,10.125C6,10.608 6.392,11 6.875,11L12.125,11C12.608,11 13,10.608 13,10.125L13,4.875Z"
          fill="rgb(239,239,239)"
        />
      </g>
      <g transform="matrix(2.28571,0,0,2.28571,-13.7143,-9.14286)">
        <path
          d="M13,4.875C13,4.392 12.608,4 12.125,4L6.875,4C6.392,4 6,4.392 6,4.875L6,10.125C6,10.608 6.392,11 6.875,11L12.125,11C12.608,11 13,10.608 13,10.125L13,4.875ZM12.563,4.875L12.563,10.125C12.563,10.366 12.366,10.563 12.125,10.563L6.875,10.563C6.634,10.563 6.438,10.366 6.438,10.125L6.438,4.875C6.438,4.634 6.634,4.438 6.875,4.438L12.125,4.438C12.366,4.438 12.563,4.634 12.563,4.875Z"
          fill="rgb(231,231,231)"
        />
      </g>
    </g>
  </svg>
);

export const IconCheckedDisabled = (): JSX.Element => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    style={{ clipRule: 'evenodd', fillRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}
  >
    <g id="checkbox-disabled">
      <g transform="matrix(2.28571,0,0,2.28571,-13.7143,-9.14286)">
        <path
          d="M13,4.875C13,4.392 12.608,4 12.125,4L6.875,4C6.392,4 6,4.392 6,4.875L6,10.125C6,10.608 6.392,11 6.875,11L12.125,11C12.608,11 13,10.608 13,10.125L13,4.875Z"
          fill="rgb(181,181,181)"
        />
      </g>
      <g transform="matrix(1,0,0,1,3.5,4.5)">
        <path d="M7.826,0L9,1.235L3.53,7L0,3.294L1.174,2.059L3.53,4.529L7.826,0Z" fill="white" />
      </g>
    </g>
  </svg>
);
