import { useTranslation } from 'react-i18next';

import {
  type ContentObjectOption,
  ContentObjectOptions,
  OptionsMap,
  type OptionsType,
  type OptionItem,
  type OptionsConfig,
} from '@openx/types';
import { useOptionsFetch } from '@openx/utils';

const useOptionFetch = (type: ContentObjectOption, queryEnabled = true): OptionItem => {
  const { t } = useTranslation();
  const isTypeLanguage = type === ContentObjectOptions.LANGUAGE;
  const targetType = isTypeLanguage ? 'targetingLanguages' : type;

  const { isLoading, data } = useOptionsFetch({
    errorMessage: t('Failed to fetch {type} options', { type }),
    mapByKey: !isTypeLanguage ? 'name' : 'id',

    // TODO UIG-540 remove casting when all options are implemented
    optionType: targetType as OptionsType,

    queryOptions: {
      enabled: queryEnabled,
    },
  });

  return { loading: isLoading, options: data };
};

export const useContentObjectOptionsConfig = (): OptionsConfig & {
  loading?: boolean;
  options?: OptionsMap;
} => {
  const { t } = useTranslation();

  const optionConfig: OptionsConfig = {
    channel: false,
    contentrating: true,
    episode: false,
    genre: true,
    language: true,
    length: undefined,
    livestream: {
      loading: false,
      options: {
        0: { id: '0', name: t('False') },
        1: { id: '1', name: t('True') },
      },
    },
    network: false,
    series: false,
    useOptionFetch,
  };

  return optionConfig;
};
