import { Button as MuiButton, IconButton as MuiIconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledButton = styled(MuiButton, { shouldForwardProp: prop => prop !== 'loading' })<{ loading?: boolean }>`
  cursor: ${({ loading }) => (loading ? 'default' : 'pointer')};
  pointer-events: ${({ loading }) => (loading ? 'none' : 'auto')};
`;

export const StyledIconButton = styled(MuiIconButton, { shouldForwardProp: prop => prop !== 'loading' })<{
  loading?: boolean;
}>`
  cursor: ${({ loading }) => (loading ? 'default' : 'pointer')};
  pointer-events: ${({ loading }) => (loading ? 'none' : 'auto')};
`;

export const StyledProgressLayerContainerDiv = styled('div')`
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

export const StyledHiddenLabelSpan = styled('span')`
  visibility: hidden;
`;
