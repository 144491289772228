import { TFunction } from 'i18next';

import { MatchedUsersOption } from '../constants';

export const getMatchedUsersLabelsMap = (t: TFunction) => {
  return {
    [MatchedUsersOption.Matched]: { id: MatchedUsersOption.Matched, name: t('Matched Users') },
    [MatchedUsersOption.Unmatched]: { id: MatchedUsersOption.Unmatched, name: t('Unmatched Users') },
    [MatchedUsersOption.MatchedByCookie]: {
      id: MatchedUsersOption.MatchedByCookie,
      name: t('Users Matched by Cookie Only'),
    },
    [MatchedUsersOption.MatchedByEids]: { id: MatchedUsersOption.MatchedByEids, name: t('Users Matched by EIDs Only') },
  };
};
