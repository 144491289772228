import i18n from 'i18next';

export const DOMAINS_LIMIT = 20000;

export const maxDomainLength = 255;

export const useBulkAddPlaceholder = () => {
  return i18n.t(`Domains may be entered as either comma, semicolon, or new line separated values.

Examples:
example.com,site.eu
or
example.com;site.eu
or
example.com
site.eu
`);
};
