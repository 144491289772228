import { omit } from 'lodash';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { AudienceOption, dealFormField, PackageFields, TargetingFields } from '@openx/types';

import { useTargetingContext } from '../../../utils';

export const UsOnlyAudienceExportTypes = {
  BIDSTREAM_PLUS: 'bidstream_plus',
  OA_MATCH: 'oa_match',
} as const;

export const useExtractAudienceExportType = (
  readonly = false
): { useAudienceExportType: () => { audienceExportType: string | null; isUsOnly: boolean } } => {
  return {
    useAudienceExportType: readonly ? useExtractAudienceExportTypeReadOnly : useExtractAudienceExportTypeForm,
  };
};

const useExtractAudienceExportTypeReadOnly = (): { audienceExportType: string | null; isUsOnly: boolean } => {
  return { audienceExportType: null, isUsOnly: false };
};

const useExtractAudienceExportTypeForm = (): { audienceExportType: string | null; isUsOnly: boolean } => {
  const { useAudienceOptionsDetailsFetch } = useTargetingContext();

  const observableAccountUid = useWatch({ name: dealFormField.ACCOUNT_UID });
  const observableTargetingAudience = useWatch({
    name: `${PackageFields.TARGETING}.${TargetingFields.AUDIENCE}`,
  });
  const selectedAudience = omit(observableTargetingAudience, [AudienceOption.INTENDED_AUDIENCE]);

  const selectedAudienceOption = useMemo(() => {
    return selectedAudience && Object.keys(selectedAudience).length > 0
      ? (Object.keys(selectedAudience)[0] as AudienceOption)
      : null;
  }, [selectedAudience]);

  const selectedAudienceValues: string[] = useMemo(() => {
    if (selectedAudienceOption && selectedAudience?.[selectedAudienceOption]) {
      const selectedOption = selectedAudience[selectedAudienceOption];
      const val = selectedOption?.val;

      if (val) {
        return val.split(',');
      }

      if (typeof selectedOption === 'string') {
        return [selectedOption];
      }
    }

    return [];
  }, [selectedAudience, selectedAudienceOption]);

  const { options } = useAudienceOptionsDetailsFetch(
    observableAccountUid,
    selectedAudienceOption,
    selectedAudienceValues
  );

  const selectedAudienceExportType = useMemo(() => {
    if (!selectedAudienceOption) {
      return null;
    }

    if (selectedAudienceOption === AudienceOption.LIVEINTENT) {
      return null;
    }

    return options[selectedAudience?.[selectedAudienceOption]?.val]?.export_type ?? null;
  }, [selectedAudienceOption, options, selectedAudience]);

  return {
    audienceExportType: selectedAudienceExportType,
    isUsOnly:
      selectedAudienceExportType === UsOnlyAudienceExportTypes.OA_MATCH ||
      selectedAudienceExportType === UsOnlyAudienceExportTypes.BIDSTREAM_PLUS,
  };
};
