import { AllTableCriteria, CriteriaChange, CriteriaDimension } from '@openx/types';

export function currentPageRange(
  pageNumber: number,
  pageSize: number,
  totalCount: number
): { min: number; max: number } {
  if (pageNumber < 1) {
    return { max: 0, min: 0 };
  }

  const min = (pageNumber - 1) * pageSize + 1;
  const maxItemForPage = pageNumber * pageSize;
  const max = totalCount < maxItemForPage ? totalCount : maxItemForPage;

  return { max, min };
}

export function applyCriteriaChange<FiltersT extends Record<string, unknown> = {}>(
  previousCriteria: AllTableCriteria<FiltersT>,
  change: CriteriaChange<CriteriaDimension> = {} as CriteriaChange<CriteriaDimension>
): AllTableCriteria<FiltersT> {
  const resetPagination: boolean = change.dimension !== CriteriaDimension.PAGINATION;
  const pagination = previousCriteria.pagination
    ? {
        ...previousCriteria.pagination,
        pageNumber: resetPagination ? 1 : previousCriteria.pagination.pageNumber,
      }
    : undefined;

  const newCriteria = {
    ...previousCriteria,
    [CriteriaDimension.PAGINATION]: pagination,
    [change.dimension]: change.value,
  };

  return newCriteria;
}
