import { COLORS } from './palette';

export const WEIGHT_LIGHT = 300;
export const WEIGHT_NORMAL = 400;
export const WEIGHT_MEDIUM = 500;
export const WEIGHT_SEMIBOLD = 600;
export const WEIGHT_EXTRABOLD = 800;

export const typography = {
  body1: {
    fontSize: 13,
    fontWeight: WEIGHT_NORMAL,
    lineHeight: '28px',
  },
  body2: {
    fontSize: 12,
    fontWeight: WEIGHT_NORMAL,
    lineHeight: '22px',
  },
  bold: {
    fontSize: 13,
    fontWeight: WEIGHT_SEMIBOLD,
    lineHeight: '28px',
  },
  button: {
    fontSize: 13,
    fontWeight: WEIGHT_SEMIBOLD,
    lineHeight: '20px',
  },
  caption: {
    fontSize: 12,
    fontWeight: WEIGHT_NORMAL,
    lineHeight: '16px',
  },
  fontFamily: '"Open Sans", sans-serif',
  fontFamilyPoppins: '"Poppins", sans-serif',
  fontSize: 13,
  fontWeightBold: WEIGHT_SEMIBOLD,
  fontWeightLight: WEIGHT_LIGHT,
  fontWeightMedium: WEIGHT_MEDIUM,
  fontWeightRegular: WEIGHT_NORMAL,
  h1: {
    fontSize: 20,
    fontWeight: WEIGHT_SEMIBOLD,
    lineHeight: '32px',
  },
  h2: {
    '&:before': {
      borderTop: `1px solid ${COLORS.REGAL}`,
      content: '""',
      display: 'block',
      marginBottom: 8,
      width: 12,
    },
    fontSize: 18,
    fontWeight: WEIGHT_SEMIBOLD,
    lineHeight: '28px',
  },
  h3: {
    fontSize: 16,
    fontWeight: WEIGHT_SEMIBOLD,
    lineHeight: '28px',
  },
  inactive: {
    color: COLORS.BLACK030,
    fontSize: 13,
    lineHeight: '28px',
  },
  overline: {
    fontSize: 11,
    fontWeight: WEIGHT_SEMIBOLD,
    lineHeight: '18px',
  },
  subtitle1: {
    fontSize: 44,
    fontWeight: WEIGHT_EXTRABOLD,
    lineHeight: '60px',
  },
  subtitle2: {
    fontSize: 20,
    fontWeight: WEIGHT_NORMAL,
    lineHeight: '28px',
  },
  useNextVariants: true,
};
