import { FormControlLabel, FormControlProps } from '@mui/material';
import { CheckboxProps } from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { ReactNode } from 'react';
import { FieldPath, Control, useController, FieldValues } from 'react-hook-form';

import { Checkbox } from '../Checkbox';

export type MuiCheckboxProps = CheckboxProps & { 'data-test'?: string };

type RHFMuiCheckboxProps<T extends FieldValues> = Omit<MuiCheckboxProps, 'name'> & {
  name: FieldPath<T>;
  control?: Control<T>;
  label: ReactNode;
  helperText?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  error?: boolean;
  margin?: FormControlProps['margin'];
};

export function RHFCheckboxField<T extends Record<string, unknown> = Record<string, boolean>>({
  name,
  control,
  label,
  ...rest
}: RHFMuiCheckboxProps<T>): JSX.Element {
  const {
    field,
    fieldState: { invalid, error },
    formState: { isSubmitting },
  } = useController({
    control,
    name,
  });

  const helperText = error?.message || rest.helperText;

  return (
    <FormControl data-test={rest['data-test']} fullWidth={rest.fullWidth} margin={rest.margin}>
      <FormControlLabel
        control={
          <Checkbox
            {...field}
            checked={!!field.value}
            disabled={isSubmitting || rest.disabled}
            isError={invalid || rest.error}
            onChange={rest.onChange ? rest.onChange : field.onChange}
          />
        }
        label={label}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
