import { useTranslation } from 'react-i18next';

import { isUrlValid } from '@openx/utils';

import { maxDomainLength } from './constants';

export const isUrlWildcardValid = (url: string) => /^(\*\.)?[^*]*(\/(.)*)?$/.test(url);

export const useValidateDomain = () => {
  const { t } = useTranslation();

  return (domain: string): string => {
    if (!domain) return '';

    const wildCardPrefix = '*.';
    const isWildcard = domain.includes('*');
    const wildcardErrorMessage = t(
      "The wildcard position is invalid. '*' must be at the beginning of the URL, followed by '.', and should have no more than one '*'."
    );

    if (isWildcard && !isUrlWildcardValid(domain)) {
      return wildcardErrorMessage;
    }

    if (domain.length > maxDomainLength) {
      return `Domain length should not be greater than ${maxDomainLength} characters`;
    }

    return isUrlValid(domain.replace(wildCardPrefix, '')) ? '' : t('Domain is invalid');
  };
};
