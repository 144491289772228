import { TFunction } from 'i18next';

import { AudienceTypeOption } from '../constants';

export const getAudienceTypeLabelsMap = (t: TFunction) => {
  return {
    [AudienceTypeOption.Over_17]: { id: AudienceTypeOption.Over_17, name: t('>17') },
    [AudienceTypeOption.Over_12]: { id: AudienceTypeOption.Over_12, name: t('Older than 12 years') },
    [AudienceTypeOption.AllAudiences]: { id: AudienceTypeOption.AllAudiences, name: t('All Audiences') },
    [AudienceTypeOption.Under_18]: { id: AudienceTypeOption.Under_18, name: t('<18') },
    [AudienceTypeOption.Under_13]: { id: AudienceTypeOption.Under_13, name: t('<13') },
  };
};
