import {
  type User,
  UserStatus,
  UserRole,
  InstanceName,
  type InstanceType,
  type AclMapping,
  type AclFlag,
  type Account,
  AccountStatus,
  type RealUser,
  CORPORATE_SSO_REALMS,
  InstanceExperience,
  AccountType,
  AccountExperience,
} from '@openx/types';

import { ACL_TYPE } from './acl';
import type { IsAllowedCheck, PermissionsCheck, PermissionsCheckParams } from './useIsAllowed';

export type AreUserAccountTypeAndExperienceValidParams = {
  user?: User;
  accountTypeId?: AccountType;
  experience?: AccountExperience;
};

type HasAccessToInventoryParams = {
  isAllowed: IsAllowedCheck;
  instance: InstanceType;
  accessUser?: User;
};

export const isPD = (user: User): boolean => {
  return user.roles.includes(UserRole.PD);
};

export const isCorporate = (user: User): boolean => {
  return ([InstanceName.CORPORATE, InstanceName.TEST_CORPORATE] as string[]).includes(user.instance_name);
};

export const hasCorporateRole = (user: User): boolean => {
  return user.roles.some(role => role.startsWith('corporate'));
};

export const isAdmin = (user: User): boolean => {
  return user.roles.includes(UserRole.ROOT) || user.roles.includes(UserRole.ADMIN);
};

export const isAllowedByAcl = (aclList: AclFlag[], aclMapping: AclMapping, acl: string): boolean => {
  const aclIndex = aclMapping[acl];
  const relatedAclFlag = aclList[aclIndex];

  return relatedAclFlag === 1;
};

export const isAllowedByCompiledAcl =
  (acl: ACL_TYPE): PermissionsCheck =>
  ({ aclList, aclMapping = {} }: PermissionsCheckParams): boolean => {
    return isAllowedByAcl(aclList || [], aclMapping, acl);
  };

export const isUserAllowedByAcl =
  (acl: ACL_TYPE): PermissionsCheck =>
  ({ userAcl, aclMapping = {} }: PermissionsCheckParams): boolean => {
    return isAllowedByAcl(userAcl || [], aclMapping, acl);
  };

export const isAccountAllowedByAcl =
  (acl: ACL_TYPE): PermissionsCheck =>
  ({ accountAcl = [], aclMapping = {} }: PermissionsCheckParams): boolean =>
    isAllowedByAcl(accountAcl, aclMapping, acl);

export const isUserActive = (user: User): boolean => {
  return user.status === UserStatus.ACTIVE;
};

export const isAccountActive = (account: Account): boolean => {
  return account.status === AccountStatus.ACTIVE;
};

export const isAllowedBySomeCompiledAcls =
  (acls: ACL_TYPE[]): PermissionsCheck =>
  ({ aclList, aclMapping = {} }: PermissionsCheckParams): boolean =>
    acls.some(acl => isAllowedByAcl(aclList || [], aclMapping, acl));

export const isMasterNetwork = (accessAccount: PermissionsCheckParams['accessAccount']): boolean => {
  if (!accessAccount) return false;

  return !accessAccount.account_uid;
};

export const isCrtRoleOnly = (user?: User): boolean => {
  return user?.roles.length === 1 && user.roles[0] === UserRole.CRT_ACCESS;
};

const isCorporateUser = (user?: RealUser): boolean => CORPORATE_SSO_REALMS.includes(user?.realm ?? '');

export const isInternalUser = ({ accessAccount, realUser }: PermissionsCheckParams): boolean =>
  isCorporateUser(realUser) && isMasterNetwork(accessAccount);

export const isBefOperator = (instance?: InstanceType): boolean => {
  return Number(instance?.buyer_experience) === 1;
};

export const exchangeIsOn = (instance: InstanceType): boolean => {
  return isBefOperator(instance) && !featureIsOn(instance, 'cross_promotion');
};

export const featureIsOn = (instance: InstanceType, feature: string): boolean => {
  return !!Number(instance.features?.[feature]);
};

export const hasAccessToTrafficSets = (isAllowed: IsAllowedCheck, instance?: InstanceType): boolean => {
  return (
    isBefOperator(instance) &&
    isAllowed(isUserAllowedByAcl(ACL_TYPE.LINEITEM_READ)) &&
    isAllowed(isUserAllowedByAcl(ACL_TYPE.ORDER_READ)) &&
    isAllowed(isUserAllowedByAcl(ACL_TYPE.ADVERTISER_READ))
  );
};

export const hasAccessToInventory = ({ isAllowed, instance, accessUser }: HasAccessToInventoryParams) => {
  if (!accessUser) {
    return false;
  }

  // it checks if instance experience is 'bef' - note: is not the same as isBefOperator()
  if (instance.experience === InstanceExperience.BEF) {
    const isRootUserAndCanAccessGlobalDeals = isAdmin(accessUser) && featureIsOn(instance, 'pmp_has_global_deals');
    const hasCorrectPermissions =
      isAllowed(isUserAllowedByAcl(ACL_TYPE.SITE_READ)) && isAllowed(isUserAllowedByAcl(ACL_TYPE.PUBLISHER_READ));

    return isRootUserAndCanAccessGlobalDeals || (hasCorrectPermissions && !exchangeIsOn(instance));
  } else {
    return isAllowed(isAllowedByCompiledAcl(ACL_TYPE.PUBLISHER_READ));
  }
};
