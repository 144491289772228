import { FieldValues } from 'react-hook-form';
import { Location } from 'react-router-dom';

type CreateAudienceNavigationStateParam = {
  formValues: FieldValues;
  location: Location;
  accountId;
};
export const createAudienceNavigationState = ({
  formValues,
  location,
  accountId,
}: CreateAudienceNavigationStateParam) => {
  const formDataKey: string = location.pathname.startsWith('/inventory/packages') ? 'package' : 'deal';

  return { accountId, [formDataKey]: formValues, redirectPath: location.pathname };
};
