/**
 * All query keys used by react-query.
 */
export const QueryKeys = {
  ACCOUNT: 'ACCOUNT',
  ACCOUNTS: 'ACCOUNTS',
  ACCOUNT_RTB_ENDPOINTS: 'ACCOUNT_RTB_ENDPOINTS',
  ADS: 'ADS',
  ADUNIT: 'ADUNIT',
  AD_FILTERS: 'AD_FILTERS',
  AD_FILTERS_LIST_ACCOUNTS: 'AD_FILTERS_LIST_ACCOUNTS',
  BRANDS: 'BRANDS',
  BRAND_FLOORS: 'BRAND_FLOORS',
  CREATIVES: 'CREATIVES',
  CREATIVE_REVIEW: 'CREATIVE_REVIEW',
  CURRENT_ACCOUNT_COOKIE_MAPPING_URL_TEMPLATE: 'CURRENT_ACCOUNT_COOKIE_MAPPING_URL_TEMPLATE',
  CURRENT_ACCOUNT_EXCHANGE_PAYMENTS_HISTORY: 'CURRENT_ACCOUNT_EXCHANGE_PAYMENTS_HISTORY',
  CURRENT_ACCOUNT_REMAINING_MONTHLY_BUDGET: 'CURRENT_ACCOUNT_REMAINING_MONTHLY_BUDGET',
  CURRENT_ACCOUNT_RTB_DATA_URL_TEMPLATE: 'CURRENT_ACCOUNT_RTB_DATA_URL_TEMPLATE',
  CURRENT_USER_EMAILS: 'CURRENT_USER_EMAILS',
  CUSTOMER: 'CUSTOMER',
  DEAL: 'DEAL',
  DEALS: 'DEALS',
  DEAL_FORECASTING: 'DEAL_FORECASTING',
  DEAL_LIBRARY: 'DEAL_LIBRARY',
  DEAL_TROUBLESHOOTING_FUNNEL: 'DEAL_TROUBLESHOOTING_FUNNEL',
  DEAL_TROUBLESHOOTING_FUNNEL_JOB_ID: 'DEAL_TROUBLESHOOTING_FUNNEL_JOB_ID',
  DEFAULT_MARKET: 'DEFAULT_MARKET',
  FEES: 'FEES',
  FLOOR_PACKAGES: 'FLOOR_PACKAGES',
  GLOBAL_SEARCH: 'GLOBAL_SEARCH',
  GRANULAR_DSP_FEE: 'GRANULAR_DSP_FEE',
  INSTANCE_FEES: 'INSTANCE_FEES',
  INVENTORIES: 'INVENTORIES',
  KEYWORDS: 'KEYWORDS',
  LINEITEM: 'LINEITEM',
  LINE_ITEMS: 'LINE_ITEMS',
  LIST_ADUNITS: 'LIST_ADUNITS',
  MARKET_OPERATORS: 'MARKET_OPERATORS',
  NETWORK_ACCOUNT_CURRENCIES: 'NETWORK_ACCOUNT_CURRENCY',
  ORDER: 'ORDER',
  PACKAGES: 'PACKAGES',
  PACKAGE_DEALS: 'PACKAGE_DEALS',
  PACKAGE_FLOORS: 'PACKAGE_FLOORS',
  PACKAGE_USED_BY: 'PACKAGE_USED_BY',
  PERFORMANCE_METRICS: 'PERFORMANCE_METRICS',
  POST_AUCTION_DISCOUNTS: 'POST_AUCTION_DISCOUNTS',
  POST_AUCTION_DISCOUNT_ITEMS: 'POST_AUCTION_DISCOUNT_ITEMS',
  PRICING_RULES: 'PRICING_RULES',
  REBATE: 'REBATE',
  SEARCH: 'SEARCH',
  SEATS: 'SEATS',
  SEAT_FEE_CAPS: 'SEAT_FEE_CAPS',
  SEAT_OBSERVED_BRANDS: 'SEAT_OBSERVED_BRANDS',
  SIMILAR_CREATIVES: 'SIMILAR_CREATIVES',
  SITE: 'SITE',
  SITES: 'SITES',
  SUB_ACCOUNTS: 'SUB_ACCOUNTS',
  TRAFFIC_SETS_DEMAND_PARTNERS: 'TRAFFIC_SETS_DEMAND_PARTNERS',
  TRAFFIC_STATS: 'TRAFFIC_STATS',
  TROUBLESHOOTING: 'TROUBLESHOOTING',
  USERS: 'USERS',
  USER_COMMENTS: 'USER_COMMENTS',
} as const;

export type QueryKeysValues = (typeof QueryKeys)[keyof typeof QueryKeys];
