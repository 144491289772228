import { Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useMemo } from 'react';

import { defaultPadding, paddingMap } from './config';
import { makeIndentation } from './Indentation';
import { HighlightedSectionProps, PaperPadding } from './types';

const StyledDiv = styled('div')<{ padding: string }>(({ theme, padding }) => ({
  background: theme.palette.background.boxLight,
  padding: paddingMap[padding],
}));

export const makeHighlightedSection = (padding: PaperPadding = defaultPadding) =>
  function ({ children, className }: HighlightedSectionProps): JSX.Element {
    const Indentation = useMemo(() => makeIndentation(padding), []);

    return (
      <Indentation className={className}>
        <Divider />
        <StyledDiv padding={padding}>{children}</StyledDiv>
        <Divider />
      </Indentation>
    );
  };
