import { SortDirection } from '@openx/types';

import { ColumnKey } from 'modules/DealLibraryList/constants';

export type SearchParamsObj = {
  params: {
    phrase?: string;
    sort?: SortDirection;
    column?: ColumnKey;
    pageNumber?: string;
  };
};

export const getSearchParamsObj = (searchParams: URLSearchParams): SearchParamsObj => ({
  params: Object.fromEntries([...searchParams]),
});

export const getPath = (params?: string): string => {
  if (!params) {
    return '/';
  }

  return `/${params}`;
};
