import { User } from 'oidc-client-ts';

import { getEnvVariables } from '../../services';
import { FEDERATION_GRANT_TYPE } from '../../state/session/constants';

import { getOIDCUserManagerSettings } from './getOIDCUserManagerSettings';
import { OIDCAuthInfo, getExpirationTime, getOIDCAuthInfoStorageKey, loadOIDCAuthInfo } from './oidcAuthInfo';
import { exchangeOIDCAuthInfo, revokeExchangeAccessToken } from './oidcAuthInfoExchange';
import { loadSSOConfig } from './ssoConfig';

function getUserStorageKey(authority: string, clientId: string): string {
  return `oidc.user:${authority}:${clientId}`;
}

// FIXME: this is only to be used in i18n for now, because it doesn't support async config
export function getOIDCAuthInfoFromStorageSync(): OIDCAuthInfo | null {
  const { ssoAuthority, ssoClientId } = getEnvVariables();

  if (!ssoAuthority || !ssoClientId) {
    return null;
  }

  const ssoConfig = loadSSOConfig();

  if (!ssoConfig || !ssoConfig.client_id) {
    return null;
  }

  const { authority, client_id } = getOIDCUserManagerSettings(ssoConfig);
  const userStorageKey = getUserStorageKey(authority, client_id);
  const storageUser = localStorage.getItem(userStorageKey);

  if (!storageUser) {
    return null;
  }

  const user = User.fromStorageString(storageUser);

  if (ssoConfig.grant_type === 'token') {
    return {
      accessToken: user.access_token,
      expirationTime: getExpirationTime(user.expires_in || 0),
      instanceUid: user.profile['instance_uid'] as string,
    };
  }

  if (ssoConfig.grant_type === FEDERATION_GRANT_TYPE) {
    return loadOIDCAuthInfo(ssoAuthority, ssoClientId);
  }

  throw new Error('Unknown grant type');
}

export function getOIDCAuthInfoFromStorageFactory() {
  let exchangeOIDCAuthInfoPromise: Promise<OIDCAuthInfo> | null = null;

  const getOIDCAuthInfoFromStorage = async (): Promise<OIDCAuthInfo | null> => {
    const { ssoAuthority, ssoClientId } = getEnvVariables();

    if (!ssoAuthority || !ssoClientId) {
      return null;
    }

    const ssoConfig = loadSSOConfig();

    if (!ssoConfig || !ssoConfig.client_id) {
      return null;
    }

    const { authority, client_id } = getOIDCUserManagerSettings(ssoConfig);
    const userStorageKey = getUserStorageKey(authority, client_id);
    const storageUser = localStorage.getItem(userStorageKey);

    if (!storageUser) {
      return null;
    }

    const user = User.fromStorageString(storageUser);

    if (ssoConfig.grant_type === 'token') {
      return {
        accessToken: user.access_token,
        expirationTime: getExpirationTime(user.expires_in || 0),
        instanceUid: user.profile['instance_uid'] as string,
      };
    }

    if (!user.id_token) {
      return null;
    }

    if (ssoConfig.grant_type === FEDERATION_GRANT_TYPE) {
      if (exchangeOIDCAuthInfoPromise) {
        return exchangeOIDCAuthInfoPromise;
      }

      exchangeOIDCAuthInfoPromise = exchangeOIDCAuthInfo(user.id_token).finally(() => {
        exchangeOIDCAuthInfoPromise = null;
      });

      return exchangeOIDCAuthInfoPromise;
    }

    throw new Error('Unknown grant type');
  };

  return getOIDCAuthInfoFromStorage;
}

export const getOIDCAuthInfoFromStorage = getOIDCAuthInfoFromStorageFactory();

export async function revokeOIDCAuthInfo(): Promise<void> {
  const { ssoAuthority, ssoClientId } = getEnvVariables();
  if (!ssoAuthority || !ssoClientId) {
    return;
  }

  const ssoConfig = loadSSOConfig();
  if (!ssoConfig) {
    return;
  }

  if (ssoConfig.grant_type !== FEDERATION_GRANT_TYPE) {
    return;
  }

  const oidcInfo = getOIDCAuthInfoFromStorageSync();
  if (!oidcInfo) {
    return;
  }

  await revokeExchangeAccessToken(ssoAuthority, ssoClientId, oidcInfo.accessToken);
}

export function removeOIDCAuthInfoFromStorage(): void {
  const { ssoAuthority, ssoClientId } = getEnvVariables();

  if (!ssoAuthority || !ssoClientId) {
    return;
  }

  const ssoConfig = loadSSOConfig();

  if (!ssoConfig || !ssoConfig.client_id) {
    return;
  }

  const { authority, client_id } = getOIDCUserManagerSettings(ssoConfig);
  const userStorageKey = getUserStorageKey(authority, client_id);

  localStorage.removeItem(userStorageKey);

  const authInfoKey = getOIDCAuthInfoStorageKey(ssoAuthority, ssoClientId);
  localStorage.removeItem(authInfoKey);
}
