import { TargetingProviderConfig } from '@openx/types';
import { useAdPlacementOptionsFetch, useCountriesOptionsFetch, useDistributionChannelFetch } from '@openx/utils';

import {
  useAccountUid,
  useCurrentInstanceState,
  useFetchInventoriesBySearchText,
  useFetchInventoryInstances,
  useInventoriesFetch,
  useFetchInventoryMarket,
  usePmpSizesFetch,
} from './InventoryAndContent';
import { useAccountFetch } from './useAccountFetch';
import { useAudienceOptionsDetailsFetch } from './useAudienceOptionsDetailsFetch';
import { useAudienceOptionsFetch } from './useAudienceOptionsFetch';
import { useCategoriesFetch } from './useCategoriesFetch';
import { useContentObjectOptionsConfig } from './useContentObjectOptionsConfig';
import { useExchangeSizeFetch } from './useExchangeSizeOptions';
import { useKeywordsOptionsFetch } from './useKeywordsOptionsFetch';
import { useLocationSourceOptionsFetch } from './useLocationSourceOptionsFetch';
import { useMetacategoriesFetch } from './useMetacategoriesFetch';
import { usePostalCodes } from './usePostalCodes';
import { useFetchByGeoData, useFetchBySearchText } from './useSearchGeoLocations';
import { useSelectRateOptionsFetch } from './useSelectRateOptionsFetch';
import { useTechnologyOptionsFetch } from './useTechnologyOptionsFetch';
import { useTrafficFilterEidsOptionsFetch } from './useTrafficFilterEidsOptionsFetch';
import { useVideoOptionsFetch } from './useVideoOptionsFetch';

const todoConfig = {
  useAccountOptionsFetch: () => {
    throw new Error('useAccountOptionsFetch not implemented.');
  },
};

export const targetingConfig: TargetingProviderConfig = {
  ...todoConfig,
  useAccountFetch,
  useAccountUid,
  useAdPlacementOptions: useAdPlacementOptionsFetch,
  useAudienceOptionsDetailsFetch,
  useAudienceOptionsFetch,
  useCategoriesFetch,
  useContentObjectOptionsConfig,
  useCountriesOptionsFetch,
  useCurrentInstanceState,
  useDistributionChannelOptions: useDistributionChannelFetch,
  useExchangeSizeFetch,
  useFetchByGeoData,
  useFetchBySearchText,
  useFetchInventoriesBySearchText,
  useFetchInventoryInstances,
  useFetchInventoryMarket,
  useInventoriesFetch,
  useKeywordsOptionsFetch,
  useLocationSourceOptionsFetch,
  useMetacategoriesFetch,
  usePmpSizesFetch,
  usePostalCodes,
  useSelectRateOptionsFetch,
  useTechnologyOptionsFetch,
  useTrafficFilterEidsOptionsFetch,
  useVideoOptionsFetch,
};
