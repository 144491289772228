import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, IconButton, TooltipProps } from '@mui/material';
import { ReactNode } from 'react';

import { getItemPadding } from '@openx/utils';

import { Ellipsis } from '../Ellipsis';
import { HighlightedPhrase } from '../HighlightedPhrase';

interface AutoCompleteRenderOptionsProps {
  option: string;
  phraseToHighlight?: string;
  dataTest?: string;
  children?: ReactNode;
  childrenLocation?: 'above' | 'below';
  getPaddingLevel?: () => number | string | undefined;
  tooltip?: boolean;
  tooltipMessage?: string;
  hasMultiple?: boolean;
  selected?: boolean;
  tooltipPlacement?: TooltipProps['placement'];
}

export const AutoCompleteRenderOptions = ({
  children,
  option,
  childrenLocation = 'below',
  phraseToHighlight,
  dataTest,
  getPaddingLevel,
  tooltip = false,
  hasMultiple = false,
  selected = false,
  tooltipPlacement,
  tooltipMessage,
}: AutoCompleteRenderOptionsProps) => {
  const render = () => (
    <>
      {childrenLocation === 'above' && children}
      <HighlightedPhrase searchPhrase={phraseToHighlight}>{option}</HighlightedPhrase>
      {childrenLocation === 'below' && children}
    </>
  );

  const Icon = selected ? RemoveIcon : AddIcon;

  if (getPaddingLevel) {
    return (
      <Box
        paddingLeft={getItemPadding(getPaddingLevel())}
        data-test={dataTest}
        display="flex"
        justifyContent="space-between"
        flex="1"
        maxWidth="100%"
      >
        <Ellipsis width="100%" tooltip={tooltip} tooltipPlacement={tooltipPlacement} tooltipMessage={tooltipMessage}>
          {render()}
        </Ellipsis>
        {hasMultiple && (
          <IconButton size="small">
            <Icon />
          </IconButton>
        )}
      </Box>
    );
  }

  return (
    <Box display="flex" justifyContent="space-between" flex="1" maxWidth="100%">
      <Ellipsis
        width="100%"
        tooltip={tooltip}
        data-test={dataTest}
        tooltipPlacement={tooltipPlacement}
        message={tooltipMessage}
      >
        {render()}
      </Ellipsis>
      {hasMultiple && (
        <IconButton size="small">
          <Icon />
        </IconButton>
      )}
    </Box>
  );
};
