import { type UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';

import { getEnvVariables } from '../../services';
import { type UserSSOConfig } from '../../state/session';
import { FEDERATION_GRANT_TYPE } from '../../state/session/constants';

export const transformSSOConfig = (
  config: UserSSOConfig | null
): { authority: string; client_id: string; scope: string } => {
  const { ssoAuthority, ssoClientId } = getEnvVariables();
  if (!ssoAuthority || !ssoClientId) {
    return {
      authority: '',
      client_id: '',
      scope: '',
    };
  }

  if (!config) {
    return {
      authority: ssoAuthority,
      client_id: ssoClientId,
      scope: 'openid email profile api',
    };
  }

  if (!config.client_id) {
    return {
      authority: '',
      client_id: '',
      scope: '',
    };
  }

  if (config.grant_type === 'token') {
    return {
      authority: ssoAuthority,
      client_id: ssoClientId,
      scope: 'openid email profile api',
    };
  }

  if (config.grant_type === FEDERATION_GRANT_TYPE) {
    return {
      authority: config.issuer,
      client_id: config.client_id,
      scope: 'openid email',
    };
  }

  throw new Error('Wrong SSO config');
};

export const getOIDCUserManagerSettings = (config: UserSSOConfig | null): UserManagerSettings => {
  const { authority, client_id, scope } = transformSSOConfig(config);

  return {
    authority,
    client_id,
    loadUserInfo: true,
    monitorSession: true,
    post_logout_redirect_uri: `${window.location.origin}/login`,
    redirect_uri: `${window.location.origin}/response-oidc`,
    revokeTokenTypes: ['refresh_token', 'access_token'],
    revokeTokensOnSignout: true,
    scope,
    stateStore: new WebStorageStateStore({ store: localStorage }),
    userStore: new WebStorageStateStore({ store: localStorage }),
  };
};
