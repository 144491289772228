import type { OptionsMap } from '@openx/types';

export function extendOptionNameWithParentName(options: OptionsMap): OptionsMap {
  const result = { ...options };

  Object.entries(options).forEach(([key, option]) => {
    result[key] = { ...option, name: `${option.parent_name}: ${option?.name}` };
  });

  return result;
}
